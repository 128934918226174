import React from "react";
import PhoneSim from '../../Images/PhoneSim/phoneSim.svg';
import ChainFighter from '../../Images/PhoneSim/chainfighter-phone-sim.mp4'
import inwood207St from '../../Images/Photo/inwood-207-street.png'
import inwood207StTopLeft from '../../Images/Photo/inwood-207-st-topleft.png'
import bAreaImage from '../../Images/Photo/b-area-street-image.png'
import dAreaImage from '../../Images/Photo/d-area-street.png'
import gAreaImage from '../../Images/Photo/g-area-image.png'

function appSim() {
    document.getElementsByClassName('cf-video')[0].style.display = 'block';
    document.getElementsByClassName('phoneSim')[0].style.display = 'none';
}

function resetPhone() {
    document.getElementsByClassName('phoneSim')[0].style.display = 'block';
    document.getElementsByClassName('cf-video')[0].style.display = 'none';
}

export default function Details() {
    return (
        <div className="container" id="myWork" style={{
            backgroundImage: `url(${inwood207St
                })`
        }}>
            <div className="item a">
                <img className="phoneSim" src={PhoneSim} height="80%" alt="This is where examples of the apps will be placed" />

                <video className="cf-video" height="80%" controls>
                    <source src={ChainFighter} type="video/mp4"></source>
                </video>
            </div>

            <div className="item b" style={{
                backgroundImage: `url(${bAreaImage
                    })`
            }} >
                <div className="simDetails">

                    <div className="cf-interaction">
                        <button className="cf-button" onClick={appSim} >CHAINFIGHTER MOBILE GAME</button>
                        <a href='https://play.google.com/store/apps/details?id=com.loogibot.chainfighter&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img className="cf-google-play" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                    </div>

                    {/* <button className="fca-button" >FLASHCARD APP</button>
                    <br /> */}
                    <br />
                    <button className="reset-button" onClick={resetPhone} > RESET PHONE</button>
                </div>
            </div>

            <div className="item c" style={{
                backgroundImage: `url(${inwood207StTopLeft
                    })`
            }}></div>
            <div className="item d" style={{
                backgroundImage: `url(${dAreaImage
                    })`
            }}>
            </div>
            <div className="item e" >
            </div>
            <div className="item f"></div>
            <div className="item g" style={{
                backgroundImage: `url(${gAreaImage
                    })`
            }}></div>
            <div className="item h"></div>
        </div>
    )
}