import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import App from './App';
import WorkPage from "./Components/Pages/Work/WorkPage";
import AboutPage from "./Components/Pages/About/AboutPage";
import LandingPage from "./Components/Pages/Landing/LandingPage";
import CHainFighterPrivacyPage from "./Components/Pages/PrivacyPolicy/ChainFighterPrivacy";

ReactDOM.render(
    <BrowserRouter>
        <div>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="work" element={<WorkPage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="landing" element={<LandingPage />} />
                <Route path="chainfighter-privacy" element={<CHainFighterPrivacyPage />} />
            </Routes>
        </div>
    </BrowserRouter>,
    document.getElementById("root"))