import React from "react";
import Details from "./Details";
import './work.css';

export default function WorkPage() {
    return (
        <div className="workPage">
            <div className="content-w">
                <Details />
            </div>
        </div>
    )
}