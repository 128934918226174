import React from "react";
import MainContent from "./MainContent";
import Contact from "./Contact"
import './about.css';

export default function AboutPage() {
    return (
        <div className="aboutPage" id="aboutMe">
            <div className="topContact">
                <Contact />
            </div>
            <MainContent />
            <div className="bottomContact" >
                <Contact />
            </div>
        </div>
    )
}