import React from "react";
import Work from '../Work/WorkPage';
import About from '../About/AboutPage';
import ChainFighterPrivacy from "../PrivacyPolicy/ChainFighterPrivacy";
import Headshot from '../../Images/Photo/headShotSideGreenIsolated.png';
import whiteDashes from '../../Images/Illustration/white-angled-dash-lines.png'
import blackDashes from '../../Images/Illustration/black-angled-dash-lines.png'
import Footer from "../Footer";

export default function Content() {
    return (
        <div className="contentFrame" >
            <div>
                <p className="roles" id="landingPage" style={{
                    backgroundImage: `url(${whiteDashes
                        })`
                }}>
                    <span className="blackText">luigiM@site:</span>~<span className="blackText">$</span> roles.sh<br />
                    <span className="blackText">$</span>Android_Engineer<br />
                    <span className="blackText">$</span>Software_Developer<br />
                    <span className="blackText">$</span>Web_Desgner
                </p>
            </div>
            <div>
            </div>
            <div className="headShot_container" style={{
                backgroundImage: `url(${blackDashes
                    })`
            }}>
                <img className="headShot" src={Headshot} alt="Luigi's headshot" />
            </div>
            <Work />
            <About />
            <ChainFighterPrivacy />
            <Footer />
        </div>
    )
}