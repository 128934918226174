import React from "react";
import { Link } from "react-router-dom";
let currentYear = new Date().getFullYear();

export default function Footer() {
    return (
        <div className="footer">
            <Link to="/Landing"><a href="#landingPage"><p className="landing-f"> HOME </p></a></Link>
            <a href="#myWork"><p className="work-f"> WORK </p></a>
            <a href="#aboutMe"><p className="about-f"> ABOUT </p></a>
            {/* <Link to="/Privacy"><p className="privacy-policy-f"> PRIVACY POLICY </p></Link> */}
            <a href="https://sites.google.com/view/chainfighter-privacy-policy/privacy-policy"><p className="privacy-policy-f"> PRIVACY POLICY </p></a>
            <p>&copy; Copyright Luigi Mota {currentYear} </p>
            <p> This website is designed, built and maintained by Luigi Mota </p>
        </div>
    )
}