import React from "react";
import './NavFrame.css'
import LuigiMotaName from '../../Images/Vector/LuigiMota.svg';

export default function NavFrame() {
    return (
        <div className="navFrame">
            <img className="luigiMotaName" src={LuigiMotaName} alt="site name" />
            <div className="navButtons">
                <a href="#myWork"><h3 className="work-n "> WORK </h3></a>
                <a href="#aboutMe"><h3 className="about-n "> ABOUT </h3></a>
                {/* <a href="#privacypolicy"></a> */}
            </div>
        </div>
    )
}