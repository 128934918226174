import React from "react";

export default function MainContent() {
    return (
        <div className="mainContent-a">
            <h1 className="IAM-a"> I AM... </h1>
            <p className="brief-a">     ... a self taught coder with six years of
                engineering and design work. I began the path of software development
                first by exploring HTML, CSS and Javascript on my free time, while
                working in the Solar industry as a principle engineer. On my journey, I employed my web building
                skills by creating sites for my company, Jotterbot, and for clients such as Domus Construction.
                <br></br>
                <br></br>
                I picked up speed as a developer with Python and taking on the challenge of building a game with TKinter.
                For the past few years, Android App Development with Java and Kotlin has
                been the main focus of my investment, converting my game to a mobile app and
                creating mobile software.
                <br></br>
                <br></br>
                Aside from programming, I love to play music, notably as a guitarist, bassist
                and drummer. I am an avid illustrator, gamer and lover of all things tech.
                Finally, I enjoy going outdoors with hiking and camping and rock climbing as my go to.</p>
        </div>
    )
}