import React from "react";
import Resume from '../../File/Luigi Mota Resume July-2023.pdf'
import githubLogo from '../../Images/Icons/icons-github-logo-WHITE.png'
import linkedinLogo from '../../Images/Icons/icons-linkedin-logo-WHITE.png'

export default function Contact() {
    return (
        <div className="contact-a">
            <p>Contact@luigimota.com</p>
            <p>LRMotaDev@gmail.com</p>

            <div className="interactions">
                <a href={Resume} download>
                    <button className="downloadResume"> Download Resume PDF </button></a>
                <div className="socialicons">
                    <a className="linkedin-c" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/luigi-mota-229a3123/">
                        <img className="linkedinLogo" src={linkedinLogo} alt="linkedin logo" />
                    </a>
                    <a className="github-c" target="_blank" rel="noopener noreferrer" href="https://github.com/Loogibot">
                        <img className="githubLogo" src={githubLogo} alt="github logo" />
                    </a>
                </div>
            </div>
        </div>
    )
}