import React from "react";
import Content from "./Content"
import NavFrame from "./NavFrame";


export default function LandingPage() {
    return (
        <div className="landingPage">
            <NavFrame />
            <Content />
        </div>
    )
}