import React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import './privacy.css';

export default function ChainFighterPrivacy() {
    return (
        <div>
            <PrivacyPolicy />
        </div>
    )
}